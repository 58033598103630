@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap");

body {
    background: #141414;
    overflow: hidden;
}

canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

h1 {
    text-align: center;
    color: white;
    text-transform: uppercase;
    padding: 1px;
    font-family: "Raleway", cursive;
    font-weight: 100;
    position: relative;
    width: 100%;
    background: linear-gradient(to right, black, #eee, black);
    white-space: nowrap;
}

h1::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -50px;
    width: 600px;
    margin-left: -300px;
    margin-top: -220px;
    height: 600px;
    z-index: -1;
}

h1 a {
    background: black;
    display: block;
    padding: 20px;
    text-decoration: none;
    letter-spacing: 30px;
    color: white;
    animation: comein 1.5s 1 ease-in-out forwards;
}

@keyframes comein {
    0% {
        letter-spacing: 80px;
        color: rgba(255, 255, 255, 0);
    }

    70% {
        letter-spacing: 20px;
    }

    100% {
        letter-spacing: 25px;
        color: rgba(255, 255, 255, 1);
    }
}

body {
    background: #000000;
    display: grid;
    place-items: center;
    margin: 0;
    overflow: scroll;
}

.buttons {
    text-align: center;
}

.btn-hover {
    width: 300px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:hover {
    background-position: 100% 0;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.btn-hover.color-1 {
    background-image: linear-gradient(to right, #5865F2, #9358f2, #dd30cf, #b62b71);
    box-shadow: 0 4px 15px 0 rgba(134, 88, 242, 0.75);
}

.btn-hover.color-2 {
    background-image: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #F24645);
    box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}