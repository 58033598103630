.App {
  text-align: center;
  width: 90%;
  justify-content: center;
}

.commands-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  margin: 30px auto;
  width: 300px;
  height: 300px;
  border-radius: 40px;
  box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: transform 1s;
  transform-style: preserve-3d;
  background: transparent;
}

.card .card_front,
.card_back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  backface-visibility: hidden;
}

.card .card_back {
  transform: rotateY(180deg);
}

.card .card_image {
  width: 100%;
  height: 100%;
  border-radius: 40px;
}

.card .card_image img {
  width: inherit;
  height: inherit;
  border-radius: 40px;
  object-fit: cover;
}

.card .card_title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-top: -310px;
  height: 40px;
}

.card .card_desc {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 25px;
  margin-top: 75px;
  height: 30px;
}

.card:hover {
  transform: scale(0.9, 0.9) rotateY(180deg);
  box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25), -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
  transition: transform 0.5s;
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}

.desc-white {
  color: white;
}

.desc-black {
  color: black;
}

@media all and (max-width: 500px) {
  .card-list {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
  }
}

.updated {
  color: #000000;
  margin-top: 25px;
}


/*
.card {
margin: 30px auto;
width: 300px;
height: 300px;
border-radius: 40px;
background-image: url('https://i.redd.it/b3esnz5ra34y.jpg');
background-size: cover;
background-repeat: no-repeat;
background-position: center;
background-repeat: no-repeat;
box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
transition: 0.4s;
}
*/
